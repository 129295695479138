export const WELCOME_TO_MEMRISE_STORAGE_KEY = 'NEW_USER_WELCOME_TO_MEMRISE';
export const EXPLAIN_LEVEL_BASED_POINTS_STORAGE_KEY = 'EXPLAIN_LEVEL_BASED_POINTS';
export const MIGRATED_TO_MY_PROGRESS_STORAGE_KEY = 'MIGRATED_TO_MY_PROGRESS';
export const CLASSIC_MIGRATION_ONBOARDING_STORAGE_KEY = 'CLASSIC_MIGRATION_ONBOARDING';
export const MY_WORDS_INTRO_STORAGE_KEY = 'MY_WORDS_INTRO';
export const SHOULD_DISPLAY_MY_ACTIVITIES_INTRO_STORAGE_KEY = 'MY_ACTIVITIES_INTRO';
export const PRE_RELEASE_USERS_SEEN_MY_ACTIVITIES_STORAGE_KEY = 'NEW_FEATURE_MY_ACTIVITIES';
export const SEEN_INCOMPLETE_ACTIVITIES_DATA_STORAGE_KEY = 'SEEN_INCOMPLETE_ACTIVITIES_DATA';

export const DEV_TOOL_ENABLE_DEBUG_PANEL_STORAGE_KEY = 'DEV_TOOL_ENABLE_DEBUG_PANEL';
export const DEV_TOOL_PRONUNCIATION_BUDDY_LEARNABLE_STORAGE_KEY =
  'DEV_TOOL_PRONUNCIATION_BUDDY_LEARNABLE';
