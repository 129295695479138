import React, { useEffect } from 'react';
import getConfig from 'next/config';
import * as S from './styles';

type SuccessResponse = {
  authorization: { id_token: string };
};

declare global {
  interface Window {
    AppleID: {
      auth: {
        signIn: () => Promise<SuccessResponse>;
      };
    };
  }
}
const { publicRuntimeConfig } = getConfig();
const { APPLE_AUTH_CLIENT_ID, APPLE_AUTH_REDIRECT_URI } = publicRuntimeConfig;

interface AppleAuthButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onSignUp: () => void;
  error: TranslatedNode;
}

const AppleAuthButton = ({
  children,
  disabled,
  error,
  onSignUp,
  ...props
}: AppleAuthButtonProps) => {
  // Should be tested in the future
  /* istanbul ignore next */
  const appleInit = async () => {
    const appleConnectLoaded = () => {
      // @ts-ignore
      AppleID.auth.init({
        clientId: APPLE_AUTH_CLIENT_ID,
        scope: 'name email',
        state: 'init',
        redirectURI: APPLE_AUTH_REDIRECT_URI,
        usePopup: true,
      });
    };

    (function (d, s, cb) {
      const fjs = d.getElementsByTagName(s)[0];
      const js = d.createElement(s);
      // @ts-ignore
      js.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      js.id = 'apple-id';
      // @ts-ignore
      fjs?.parentNode.insertBefore(js, fjs);
      js.addEventListener('load', () => cb());
    })(document, 'script', appleConnectLoaded);
  };

  /* istanbul ignore next */
  useEffect(() => {
    async function loadApple() {
      if (document.getElementById('apple-id') === null) {
        await appleInit();
      }
    }

    loadApple().catch(e => console.log(`Load Apple failed: ${e}`)); // eslint-disable-line no-console
  }, []);

  return (
    <S.AppleButton
      buttonType="primary"
      buttonSize="small"
      wide
      icon="appleButtonIcon"
      onClick={onSignUp}
      disabled={disabled}
      error={error}
      {...props}
    >
      {children}
    </S.AppleButton>
  );
};

export default AppleAuthButton;
