import styled, { css } from 'styled-components';
import Icon from 'src/components/Icon';

export const DropdownWrapper = styled.div<{ $minWidth?: string }>(
  ({ theme, $minWidth }) => css`
    min-width: ${$minWidth ?? theme.sz.s64};
  `,
);

export const DropdownSection = styled.ul<{ $borderWidth?: string }>(
  ({ theme, $borderWidth }) => css`
    margin: 0;
    padding: ${theme.sz.s4};
    border-bottom-width: ${$borderWidth ?? theme.sz.s05};
    border-bottom-color: ${theme.co.secondary90};
    &:not(:last-child) {
      border-bottom-style: solid;
    }

    &:first-child {
      padding-top: ${theme.sz.s6};
    }
    &:last-child {
      padding-bottom: ${theme.sz.s6};
    }

    ${$borderWidth &&
    css`
      &:first-child {
        padding-top: ${theme.sz.s4};
      }
      &:last-child {
        padding-bottom: ${theme.sz.s4};
      }
    `}
  `,
);

export const DropdownSubheading = styled.h2(
  ({ theme }) => css`
    color: ${theme.co.secondary50};
    font-size: ${theme.DEPRECATED_ty.medium};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin: 0;
    margin-bottom: ${theme.sz.s3};
  `,
);

export const DropdownItemWrapper = styled.li<{
  $isIconOnRight?: boolean;
}>(
  ({ $isIconOnRight }) => css`
    display: flex;
    align-items: center;
    min-height: ${props => props.theme.sz.s6};
    margin-bottom: ${props => props.theme.sz.s3};
    cursor: pointer;
    ${$isIconOnRight &&
    css`
      justify-content: space-between;
      flex-direction: row-reverse;
    `};

    svg {
      transform: scale(75%);
    }

    &:last-child {
      margin: 0;
    }
  `,
);

export const StyledLink = styled.a<{ $highlight?: boolean }>(
  ({ theme, $highlight }) => css`
    text-decoration: none;
    color: ${$highlight ? theme.co.error50 : theme.co.secondary0};
    ${theme.typography.button.s}
    font-weight: ${theme.DEPRECATED_ty.semiBold};
    width: 100%;
  `,
);

export const StyledIcon = styled(Icon)`
  width: ${props => props.theme.sz.s8};
  height: ${props => props.theme.sz.s8};
  margin-right: ${props => props.theme.sz.s2};

  svg {
    width: ${props => props.theme.sz.s8};
    height: ${props => props.theme.sz.s8};
  }
`;

interface DropdownItemLabelProps {
  $highlight?: boolean;
  $isDarkTheme?: boolean;
  $fontSize?: string;
  $fontWeight?: string;
}

export const DropdownItemLabel = styled.span<DropdownItemLabelProps>`
  color: ${props => {
    if (props.$highlight) {
      return props.theme.co.error50;
    } else if (props.$isDarkTheme) {
      return props.theme.co.neutral99;
    } else {
      return props.theme.co.secondary0;
    }
  }};
  font-size: ${props => (props.$fontSize ? props.$fontSize : props.theme.DEPRECATED_ty.small)};
  font-weight: ${props =>
    props.$fontWeight ? props.$fontWeight : props.theme.DEPRECATED_ty.semiBold};
`;

export const DropdownImageIcon = styled.img`
  width: ${props => props.theme.sz.s8};
  height: ${props => props.theme.sz.s8};
  border-radius: 50%;
  margin-right: ${props => props.theme.sz.s2};
`;

export const Description = styled.a(
  ({ theme }) => css`
    margin-left: ${theme.sz.s2};
    font-size: ${theme.DEPRECATED_ty.smaller};
    color: ${theme.co.secondary50};
  `,
);
