import React from 'react';
import { svgToCssUrl } from 'src/utils/svgToCssUrl';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import PremiumStar from '../Icon/icons/premium-star.svg';
import { SvgHelperWrapper } from '../Icon/iconHelper';

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.sz.s8};
  `,
);

export const HeaderDropdownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  user-select: none;
`;

const premiumStar = (() => {
  return `${svgToCssUrl(<SvgHelperWrapper Svg={PremiumStar} />)}`;
})();

export const HeaderDropdownButton = styled.button<{
  $isPremium: boolean;
}>(
  ({ theme, $isPremium }) => css`
    background: ${theme.co.neutral98};
    border: 0;
    padding: ${theme.sz.s1};
    border-radius: ${theme.bo.round};
    margin-bottom: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${theme.co.neutral95};
    }

    ${$isPremium
      ? css`
          &::after {
            content: '';
            background-image: ${premiumStar};
            position: absolute;
            background-repeat: no-repeat;
            height: ${theme.sz.s4};
            width: ${theme.sz.s4};
            bottom: ${theme.sz.s1};
            /*! @noflip */
            right: ${theme.sz.s05};
          }
        `
      : ''}
  `,
);
