import React, { useEffect } from 'react';
import getConfig from 'next/config';
import * as S from './styles';

declare global {
  interface Window {
    FB: {
      login: (
        cb: (response: { authResponse: { accessToken: string; grantedScopes: unknown } }) => void,
        options?: { scope: string; return_scopes: boolean },
      ) => void;
    };
  }
}

const { publicRuntimeConfig } = getConfig();
const { FACEBOOK_APP_ID } = publicRuntimeConfig;

interface FacebookAuthButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onSignUp: () => void;
  error: TranslatedNode;
}

const FacebookAuthButton = ({
  children,
  disabled,
  error,
  onSignUp,
  ...props
}: FacebookAuthButtonProps) => {
  /* istanbul ignore next: going to lean on Cypress testing to test facebook auth */
  const fbInit = async () => {
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      });
      // @ts-ignore
      FB.AppEvents?.logPageView();
    };
  };

  /* istanbul ignore next: going to lean on Cypress testing to test facebook auth */
  const loadSdk = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      // @ts-ignore
      fjs?.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  useEffect(() => {
    async function loadFacebook() {
      /* istanbul ignore else: empty else branch */
      if (document.getElementById('facebook-jssdk') === null) {
        await fbInit();
        loadSdk();
      }
    }

    loadFacebook().catch(e => console.log(`Load Facebook failed: ${e}`)); // eslint-disable-line no-console
  }, []);

  return (
    <S.FacebookButton
      buttonType="primary"
      buttonSize="small"
      wide
      icon="facebookShareIcon"
      onClick={onSignUp}
      disabled={disabled}
      error={error}
      {...props}
    >
      {children}
    </S.FacebookButton>
  );
};

export default FacebookAuthButton;
