import React, { FC, KeyboardEvent } from 'react';
import Link from 'next/link';
import { IconName } from '..';
import * as S from './styles';

type BaseItemWithLabel = {
  theme?: 'light' | 'dark';
  labelText: string;
  description?: string;
  highlight?: boolean;
  fontSize?: string;
  fontWeight?: string;
};

type DropdownImageItem = BaseItemWithLabel & {
  type: 'image';
  imageSrc: string;
  onClick?: () => void;
};

type DropdownIconItem = BaseItemWithLabel & {
  type: 'icon';
  iconCode: IconName;
  ariaLabel?: string;
  onClick?: () => void;
  isIconOnRight?: boolean;
};

export type DropdownLabelItem = BaseItemWithLabel & {
  type: 'label';
  onClick?: () => void;
};

type DropdownLinkItem = {
  type: 'link';
  linkText: string;
  href: string;
  newTab?: boolean;
  onClick?: () => void;
  theme?: 'light' | 'dark';
  highlight?: boolean;
};

type DropdownElementItem = {
  type: 'element';
  element: React.ReactElement;
  theme?: 'light' | 'dark';
  onClick?: () => void;
};

export type DropdownItemProps =
  | DropdownImageItem
  | DropdownIconItem
  | DropdownLabelItem
  | DropdownLinkItem
  | DropdownElementItem;

const DropdownItem: FC<DropdownItemProps> = props => {
  const onKeyup = (event: KeyboardEvent<HTMLLIElement | HTMLAnchorElement>) => {
    const { onClick } = props;

    if (event.key === 'Enter' || event.key === ' ') {
      onClick && onClick();
    }
  };

  const renderLabel = ({
    theme,
    highlight,
    labelText,
    description,
    fontSize,
    fontWeight,
  }: BaseItemWithLabel) => (
    <S.DropdownItemLabel
      $isDarkTheme={theme === 'dark'}
      $highlight={highlight}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
    >
      {labelText}
      {description && (
        <S.Description data-testid="dropdownLabelDescription">{description}</S.Description>
      )}
    </S.DropdownItemLabel>
  );

  switch (props.type) {
    case 'element':
      return props.element;
    case 'icon':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          $isIconOnRight={props.isIconOnRight}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
          aria-label={props.ariaLabel}
        >
          <S.StyledIcon name={props.iconCode} />
          {renderLabel(props)}
        </S.DropdownItemWrapper>
      );
    case 'label':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
        >
          {renderLabel(props)}
        </S.DropdownItemWrapper>
      );
    case 'image':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
        >
          <S.DropdownImageIcon src={props.imageSrc} alt="" />
          {renderLabel(props)}
        </S.DropdownItemWrapper>
      );
    case 'link':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          role="option"
          id={`default_select_namespace_element_${props.linkText}`}
        >
          <Link href={props.href} passHref legacyBehavior>
            <S.StyledLink
              {...(props.newTab ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
              $highlight={props.highlight}
            >
              {props.linkText}
            </S.StyledLink>
          </Link>
        </S.DropdownItemWrapper>
      );
  }
};

export default DropdownItem;
