import {
  EXPLAIN_LEVEL_BASED_POINTS_STORAGE_KEY,
  WELCOME_TO_MEMRISE_STORAGE_KEY,
  MIGRATED_TO_MY_PROGRESS_STORAGE_KEY,
  PRE_RELEASE_USERS_SEEN_MY_ACTIVITIES_STORAGE_KEY,
} from 'src/constants/storageKeys.const';
import { clearSessionStorage, forEachKeyInLocalStorage } from 'src/utils/webStorageUtils';

const preservedLocalStorageKeys = [
  WELCOME_TO_MEMRISE_STORAGE_KEY,
  EXPLAIN_LEVEL_BASED_POINTS_STORAGE_KEY,
  MIGRATED_TO_MY_PROGRESS_STORAGE_KEY,
  PRE_RELEASE_USERS_SEEN_MY_ACTIVITIES_STORAGE_KEY,
];

export function cleanupWebStoragesOnLogout() {
  clearSessionStorage();

  forEachKeyInLocalStorage(key => {
    if (!preservedLocalStorageKeys.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}
