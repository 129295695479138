import React, { ComponentProps, FC, ReactNode, useState, type JSX } from 'react';
import { DropdownMenu, Popout, PopoutParentContainer, Tooltip } from 'src/components';
import * as S from './styles';

interface HeaderDropdownIconProps
  extends Pick<ComponentProps<typeof DropdownMenu>, 'dropdownSections'> {
  tooltipText: string | JSX.Element | ReactNode;
  isPremium: boolean;
  ariaText: string;
}

const HeaderDropdownIcon: FC<HeaderDropdownIconProps> = ({
  dropdownSections,
  tooltipText,
  isPremium,
  ariaText,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <S.HeaderDropdownIconWrapper>
      <PopoutParentContainer>
        <Tooltip
          placement="bottom"
          tooltip={tooltipText}
          disabled={showDropdown || !tooltipText}
          fullWidth
        >
          <S.HeaderDropdownButton
            $isPremium={isPremium}
            data-testid="headerDropdownIcon-profileIcon"
            onClick={() => setShowDropdown(!showDropdown)}
            aria-label={ariaText}
            title={ariaText}
          >
            <S.StyledIcon name="profileIcon" />
          </S.HeaderDropdownButton>
        </Tooltip>
        <Popout
          show={showDropdown}
          positionX="left"
          limitHeight
          onClickBackdrop={() => setShowDropdown(false)}
        >
          <DropdownMenu
            dropdownSections={dropdownSections}
            onClickItem={() => setShowDropdown(false)}
          />
        </Popout>
      </PopoutParentContainer>
    </S.HeaderDropdownIconWrapper>
  );
};

export default HeaderDropdownIcon;
