import React, { FC } from 'react';
import { Stack } from 'src/components';
import HeaderDropdownIcon from 'src/components/HeaderDropdownIcon';
import { defineMessages, useIntl } from 'react-intl';
import { useUserContext } from 'src/context/user';
import { DropdownSectionProps } from 'src/components/DropdownMenu';
import { ROUTES } from 'src/constants/routes.const';
import { PlatformPage } from 'src/utils/analytics/analytics.const';
import { getAnalyticsLanguageInfo } from 'src/utils/analytics/getAnalyticsLanguageInfo';
import { getAnalyticsUserInfo } from 'src/utils/analytics/getAnalyticsUserInfo';
import * as S from '../styles';

const msgs = defineMessages({
  profileTooltip: { id: 'dashboard_tooltip_profile_dropdown' },
  scenariosProfileTooltip: { id: 'scenario_dashboard_tooltip_profile_dropdown' },
  yourAccount: { id: 'scenario_dashboard_tooltip_profile_dropdown_your_account' },
  learningSettings: { id: 'web_ls_menu' },
  profileSettings: { id: 'web_ls_menu_profile' },
  logOut: { id: 'sign_out' },
  help: { id: 'header_link_help' },
});

export const ProfileDropdown: FC = () => {
  const user = useUserContext();
  const intl = useIntl();

  const profileSections: DropdownSectionProps[] = [
    {
      dropdownItems: [
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.profileSettings),
          href: ROUTES.settings.user,
          onClick: () => {
            window.dataLayer.push({
              event: 'platform',
              page_screen_type: 'Platform',
              page_screen_name: PlatformPage.PROFILE,
              ...getAnalyticsLanguageInfo(intl),
              ...getAnalyticsUserInfo(user?.user),
            });
          },
        },
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.learningSettings),
          href: ROUTES.settings.learning,
        },
      ],
    },
    {
      dropdownItems: [
        {
          type: 'link',
          newTab: true,
          linkText: intl.formatMessage(msgs.help),
          href: 'https://memrisebeta.zendesk.com',
        },
      ],
    },
    {
      dropdownItems: [
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.logOut),
          highlight: true,
          href: ROUTES.signOut,
        },
      ],
    },
  ];

  const profileIconText = intl.formatMessage(msgs.yourAccount);

  return (
    <Stack $direction="horizontal" $spacing="s8">
      <HeaderDropdownIcon
        dropdownSections={profileSections}
        tooltipText={intl.formatMessage(msgs.scenariosProfileTooltip, {
          yourAccount: <strong key="strong">{profileIconText}</strong>,
          email: <S.NoWrap key="NoWrap">{user.user?.email}</S.NoWrap>,
        })}
        isPremium={!!user.user?.isPro}
        ariaText={profileIconText}
      />
    </Stack>
  );
};
